import { ref } from 'vue'
import { defineStore } from 'pinia'
import { persistedState } from '#imports'

export const useOnboardStore = defineStore(
  'onboard',
  () => {
    const welcomed = ref<boolean>(false)
    const sessionOnboarded = ref<boolean>(false)
    const ctaShown = ref<boolean>(false)

    const setSessionOnboarded = (value: boolean) => {
      sessionOnboarded.value = value
    }

    const setWelcomed = (value: boolean) => {
      welcomed.value = value
    }

    const setCtaShown = (value: boolean) => {
      ctaShown.value = value
    }

    return {
      welcomed,
      sessionOnboarded,
      ctaShown,
      setCtaShown,
      setWelcomed,
      setSessionOnboarded,
    }
  },
  {
    persist: [
      {
        storage: persistedState.cookiesWithOptions({
          sameSite: 'strict',
          expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        }),
        paths: ['welcomed'],
      },
      {
        storage: persistedState.sessionStorage,
        paths: ['ctaShown', 'sessionOnboarded'],
      },
    ],
  },
)
